<template>
  <div class="thumb-example">
    <div class="img_">
      <img v-if="!list[index].video" :src="list[index].src" alt="" srcset="" />
      <video
        v-else
        :x5-playsinline="true"
        :webkit-playsinline="true"
        :playsinline="true"
        controls
      >
        <source :src="list[index].src" />
      </video>
    </div>
    <div class="bot">
      <div class="prev" @click="prev">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="slide_imgs">
        <div
          class="slide_imgs_container"
          ref="container"
          :style="{ transform: `translate3d(${leftWidth}px, 0px, 0px)` }"
        >
          <div
            v-for="(li, i) in list"
            :key="i"
            :class="index == i ? 'act' : ''"
          >
            <img
              v-if="!li.video"
              :src="li.src"
              @click="changeIndex(i)"
              alt=""
              srcset=""
            />
            <video v-else @click="changeIndex(i)">
              <source :src="li.src" />
            </video>
          </div>
        </div>
      </div>
      <div class="next" @click="next">
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data () {
    return {
      imgObj: {},
      index: 0,
      list: [],
      leftWidth: "0"
    };
  },
  props: ["chooseIndex", "imgListUrl"],
  mounted () {
    this.imgListUrl.forEach(element => {
      var flieArr = element.split('.')
      var suffix = flieArr[flieArr.length - 1]
      var videolist = ['mp4', 'm2v', 'mkv']
      var result = videolist.some((item) => {
        return item == suffix
      })
      this.list.push({
        video: result,
        msrc: element,
        src: element,
        h: 375
      })
    });
    // console.log(this.list, 887);
  },
  methods: {
    prev () {
      if (this.index == 0) return
      this.index--
      this.transformStyle()
    },
    next () {
      if (this.index == this.list.length) return
      this.index++
      this.transformStyle()
    },
    changeIndex (index) {
      this.index = index
      this.transformStyle()
    },
    transformStyle () {

      if (this.index > 2) {
        this.$nextTick(() => {
          this.leftWidth = "-" + (this.index - 2) * 79
        })
      } else {
        this.leftWidth = "0"
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.thumb-example {
  width: 400px;
  height: 480px;
  background-color: #fff;
  .img_ {
    width: 400px;
    height: 400px;
    img,
    video {
      width: 100%;
      height: 100%;
    }
  }

  .bot {
    display: flex;
    margin-top: 20px;
    .prev,
    .next {
      flex: 0 0 36px;
      width: 36px;
      height: 64px;
      background-color: #ffffff;
      border: 1px solid #eeeeee;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: #030303;
        font-size: 16px;
      }
    }
    .slide_imgs {
      width: 300px;
      margin: 0 14px;
      overflow: hidden;
      .slide_imgs_container {
        display: flex;
        align-items: center;
        transition: 0.3s;
        // transform: translate3d(0px, 0px, 0px);
        div {
          flex: 0 0 64px;
          width: 64px;
          height: 64px;
          margin-right: 15px;
          border: 2px solid #d9d7d7;
          img,
          video {
            width: 100%;
            height: 100%;
            background-color: #d9d7d7;
          }
        }
        .act {
          border: 2px solid #f95e2e;
        }
      }
    }
  }
}
.red {
  border: 5px solid red;
}
/deep/.swiper-wrapper {
  transition-duration: 300ms;
}
#myVideo {
  width: 100%;
  height: 100%;
}
</style>