<template>
  <div class="box">
    <Topbar :shopcar="shopcarlength"></Topbar>
    <SuS></SuS>
    <!-- <div class="pc-title">首页>玄关画>举木画架平立两用美术油画架</div> -->
    <div style="height: 50px"></div>
    <!-- 商品详情顶部 -->
    <div class="content-top">
      <div class="content-top-left">
        <!-- 商品详情大图 -->
        <left-swiper
          v-if="imgListUrl.length > 0"
          :chooseIndex="chooseIndex"
          :imgListUrl="imgListUrl"
          ref="swiperson"
        ></left-swiper>
      </div>
      <!-- 右边 -->
      <div class="content-top-right">
        <div class="shop-font-bg">
          {{ detail.GoodsName }}
        </div>
        <!-- <div class="shop-small-font">原创设计，现代时尚</div> -->
        <div class="shop-detail">
          <div class="price-font">
            <div class="price-font-font">价格</div>
            <div class="price-font-number ml2" v-if="detail.DefaultPoints > 0">
              {{ detail.DefaultPoints }}积分
            </div>
            <div class="price-font-number ml" v-if="detail.DefaultPoints > 0">
              +
            </div>
            <div class="price-font-icon ml">￥</div>
            <div class="price-font-number">{{ detail.DefaultPrice }}</div>
          </div>
          <div class="coupon" v-if="couponTitle">
            <div class="price-font-font">领券</div>
            <div class="coupon-bottom">{{ couponTitle }}</div>
            <div class="coupon-font">先领优惠券，再下单</div>
            <div class="coupon-last" @click="youhui">点击领取</div>
          </div>
          <!-- <div class="integral">
            <div class="price-font-font">积分</div>
            <div class="integral-bottom">购买可得50积分</div>
          </div> -->
        </div>
        <div class="all-img">
          <div class="all-img-font">商品规格</div>
          <div class="all-img-right">
            <div
              class="all-img-img"
              v-for="(item, index) in sku.tree[0].v"
              :key="index"
            >
              <div
                @mouseover="isgg(item, index)"
                @mouseleave="isnogg(item)"
                @click="addischeck2(index, item)"
                :style="{ border: act == index ? '1px solid red' : 'none' }"
              >
                <img :src="item.imgUrl" alt="" />
              </div>
              <div class="shop-img-font" v-if="'true' == item.ischeck">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="num">
          <div class="all-img-font">数量</div>
          <div class="jisuan">
            <div class="sub" @click="sub()">-</div>
            <el-input
              class="num-number"
              type="number"
              v-model="nums"
              placeholder="数量"
            ></el-input>
            <!-- <div class="num-number">{{ nums }}</div> -->
            <div class="add" @click="add()">+</div>
          </div>
        </div>
        <div class="two-bottom">
          <div class="one" @click="topay()">立即购买</div>
          <div class="two" @click="onAddCartClicked()">加入购物车</div>
        </div>
      </div>
    </div>

    <!-- 优惠弹窗 -->
    <!-- 用户反馈 -->
    <el-dialog title="领取优惠卷" :visible.sync="dialogVisible" width="30%">
      <div class="Yhcontent">
        <!-- 优惠列表 -->
        <div class="yh">
          <div class="yh_content" v-for="(item, i) in couponList" :key="i">
            <div class="yh_content_money">
              <p>
                <span style="fontsize: 11px">￥</span>{{ item.DiscountMoney }}
              </p>
              <p>满{{ item.FullMoney }}元可用</p>
            </div>
            <div class="yh_content_detail">
              <p>{{ item.Title }}</p>
              <p>有效期至{{ item.EndTime.split(" ")[0] }}</p>
            </div>
            <div class="btns" @click="postReceiveShopCoupon(item)">
              立即领取
            </div>
            <img
              v-if="item.IsReceive >= 1"
              class="ylq"
              src="../../../assets/ShopImg/ylq.png"
              alt=""
            />
          </div>
          <div class="two" @click="toshopmy()">去我的优惠券查看 ></div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 中间部分 -->
    <div class="content">
      <div class="content-hot">
        <div class="content-hot-top">热销推荐</div>
        <div
          class="content-hot-item"
          v-for="(item, index) in homeHotList"
          :key="index"
          @click="changedetail(item)"
        >
          <div class="sanjiao">
            <div class="sanjiaonum">{{ index + 1 }}</div>
          </div>
          <div class="hot-img">
            <img :src="item.CoverImg" alt="" />
          </div>
          <div class="hot-font">
            {{ item.GoodsName }}
          </div>
          <div class="hot-price">
            <div class="hot-price-icon">￥</div>
            <div class="hot-price-nums">{{ item.DefaultPrice }}</div>
          </div>
        </div>
      </div>

      <div class="content-body">
        <div class="content-body-top">
          <div
            :class="[
              this.isdetail ? 'content-body-left' : ' content-body-left2',
            ]"
            @click="changebody(1)"
          >
            商品详情
          </div>
          <div
            :class="[
              this.isdetail ? 'content-body-right' : ' content-body-right2',
            ]"
            @click="changebody(2)"
          >
            商品评价
          </div>
        </div>
        <div
          style="padding: 10px"
          v-html="detail.Introduce"
          v-if="isdetail"
        ></div>
        <div class="content-body-center2" v-if="!isdetail">
          <div class="outisde">
            <div class="zanwupinglun" v-if="this.evaluationList == 0">
              <nocontent name="暂无评论"></nocontent>
            </div>
            <div
              class="comment_content"
              v-for="(item, i) in evaluationList"
              :key="i"
            >
              <!-- 资料 -->

              <div class="user">
                <img :src="hostUrl + item.HeadImg" alt="" />
                <div class="user_name">
                  <div class="star">
                    <el-rate
                      v-model="item.Score"
                      disabled
                      show-score
                      text-color="#ff9900"
                      score-template="{value}"
                    >
                    </el-rate>
                  </div>
                  <p>{{ item.StudentName }}</p>
                  <p>
                    {{
                      getDateDiff(getDateTimeStamp(item.EvaluateTime)) +
                      " | " +
                      item.SpecName
                    }}
                  </p>
                </div>
              </div>

              <!-- 内容 -->

              <div class="comment_cont">
                {{ item.Evaluation }}
              </div>

              <!-- 图片 -->
              <div class="image" v-if="item.EvaluationImg">
                <div
                  class="imgs"
                  v-for="(item, i) in item.EvaluationImg.split(',')"
                  :key="i"
                >
                  <img :src="item" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="content-body-img">
                    <img src="../../../assets/images/detail.png" alt="">
                </div> -->
      </div>
    </div>
    <Foter></Foter>
  </div>
</template>
<script>
import {
  qeryShopGoodsList,
  queryShopGoodsDetail,
  queryCouponList,
  queryGoodsEvaluation,
  receiveShopCoupon,
  shopCartAdd,
  queryShopCartList
} from "@/api/shop";
import Topbar from "../../PCshop/components/topbar.vue";
import Foter from "../../PCshop/components/shopfooter.vue";
import SuS from "../../PCshop/components/suspen.vue"; //悬浮
import nocontent from "../../shop/components/nocontent.vue";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import leftSwiper from "../../PCshop/components/swiperSon.vue";
import { Toast } from "vant";
export default {
  components: {
    Topbar,
    Foter,
    SuS,
    Swiper,
    SwiperSlide,
    directive,
    leftSwiper,
    nocontent,
  },

  data () {
    return {
      hostUrl: '',
      skuprice: 0,
      couponList: [],
      shopcarlength: 0,
      dialogVisible: false,
      guigeId: 0,
      isdetail: true,
      homeHotList: [],
      goodsHotList: [],
      evaluationList: [], //评论列表
      isyouhui: false,
      act: null,
      isguige: false,
      couponTitle: "",
      detail: {},
      directives: {
        swiper: directive,
      },
      imgListUrl: [], //图片数组
      chooseIndex: null, //轮播图所选择的index
      nums: 1,
      goodsId: 0, //商品id
      sku: {
        // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
        // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
        tree: [
          {
            k: "商品规格", // skuKeyName：规格类目名称
            k_s: "s1", // skuKeyStr：sku 组合列表（下方 list）中当前类目对应的 key 值，value 值会是从属于当前类目的一个规格值 id
            v: [],
            largeImageMode: false, //  是否展示大图模式
          },
        ],
        // 所有 sku 的组合列表，比如红色、M 码为一个 sku 组合，红色、S 码为另一个组合
        list: [],
        price: "1.00", // 默认价格（单位元）
        stock_num: 227, // 商品总库存
        collection_id: 2261, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
        none_sku: false, // 是否无规格商品
        hide_stock: false, // 是否隐藏剩余库存
      },
      goods: {
        // 默认商品 sku 缩略图
        picture: "https://img01.yzcdn.cn/2.jpg",
      },
      customStepperConfig: {
        // 自定义限购文案
        quotaText: "每次限购xxx件",
        // 自定义步进器超过限制时的回调
        handleOverLimit: (data) => {
          const { action, limitType, quota, quotaUsed, startSaleNum } = data;

          if (action === "minus") {
            Toast(
              startSaleNum > 1 ? `${startSaleNum}件起售` : "至少选择一件商品"
            );
          } else if (action === "plus") {
            // const { LIMIT_TYPE } = Sku.skuConstants;
            if (limitType === LIMIT_TYPE.QUOTA_LIMIT) {
              let msg = `单次限购${quota}件`;
              if (quotaUsed > 0) msg += `，你已购买${quotaUsed}`;
              Toast(msg);
            } else {
              Toast("库存不够了");
            }
          }
        },
        // 步进器变化的回调
        handleStepperChange: (currentValue) => { },
        // 库存
        stockNum: 1999,
        // 格式化库存
        stockFormatter: (stockNum) => { },
      },

      initialSku: {
        s1: "1",
        selectedNum: 1,
      },
      properties: [],
      userInfo: {},
    };
  },
  created () {
    this.hostUrl = this.GLOBAL.hostUrl;
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      this.userInfo.Id = 0;
    }

    this.goodsId = this.$route.query.id;
    this.getQueryShopGoodsDetail();
    this.getQueryCouponList();
    this.postQeryHotShopGoodsList();
    this.getQueryGoodsEvaluation();
    //  this.sku.list=[];
  },
  methods: {
    //获取商品详情
    async getQueryShopGoodsDetail () {
      const res = await queryShopGoodsDetail(this.goodsId);
      this.detail = res.response;
      console.log(this.detail, 888883)
      this.list = this.detail.Images.split(",");
      this.imgListUrl = this.list;
      // 组装规格数据
      this.sku.price = this.detail.DefaultPrice;
      var spec = this.detail.GoodsSpecList;
      for (var i = 0; i < spec.length; i++) {
        if (spec[i].IsDefault == 1) {
          this.sku.stock_num = spec[i].Stock;
          this.goods.picture = spec[i].ImageUrl;
          this.specName = spec[i].SpecName;
          this.initialSku.s1 = spec[i].Id;
        }
        let obj = {};
        obj.id = spec[i].Id;
        obj.name = spec[i].SpecName;
        obj.imgUrl = spec[i].ImageUrl;
        obj.previewImgUrl = spec[i].ImageUrl;
        this.sku.tree[0].v.push(obj);
        console.log(this.sku.tree[0].v, "yjc");
        let obj2 = {};
        obj2.id = spec[i].Id;
        obj2.s1 = spec[i].Id;
        // obj2.s2 = spec[i].Id;
        obj2.price = spec[i].Price * 100;
        obj2.oprice = spec[i].OriginalPrice;
        obj2.stock_num = spec[i].Stock;

        this.sku.list.push(obj2);
      }
      console.log(this.initialSku);
    },
    // 获取优惠券列表
    async getQueryCouponList () {
      let params = {};
      params.Status = 0;
      params.GoodsId = this.goodsId;
      params.pageIndex = 1;
      params.pageSize = 100;
      params.UserId = this.userInfo.Id;
      const res = await queryCouponList(params);
      this.couponList = res.response.data;
      this.couponTitle =
        this.couponList.length > 0
          ? "满" +
          this.couponList[0].FullMoney +
          "减" +
          this.couponList[0].DiscountMoney
          : "";
      console.log(this.couponList, 8877);
    },

    // 热门列表
    async postQeryHotShopGoodsList () {
      let parm = {};
      parm.pageIndex = 1;
      parm.pageSize = this.pageSize;
      parm.IsHot = 1;
      const res = await qeryShopGoodsList(parm);
      this.goodsHotList = res.data;
      console.log(this.goodsHotList, 888);
      this.homeHotList = this.goodsHotList.splice(0, 3);
      this.isLoad = false;
    },

    //评论
    async getQueryGoodsEvaluation () {
      let params = {};
      params.GoodsId = this.goodsId;
      params.pageIndex = this.pageIndex;
      params.pageSize = this.pageSize;
      const res = await queryGoodsEvaluation(params);
      this.evaluationList = res.response.data;
      console.log(this.evaluationList, '评论列表');
    },
    getDateTimeStamp (dateStr) {
      return Date.parse(dateStr.replace(/-/gi, "/"));
    },
    getDateDiff (dateTimeStamp) {
      let result = "";
      var minute = 1000 * 60;
      var hour = minute * 60;
      var day = hour * 24;
      var halfamonth = day * 15;
      var month = day * 30;
      var now = new Date().getTime();
      var diffValue = now - dateTimeStamp;
      if (diffValue < 0) {
        //若日期不符则弹窗口告之,结束日期不能小于开始日期！
      }
      var monthC = diffValue / month;
      var weekC = diffValue / (7 * day);
      var dayC = diffValue / day;
      var hourC = diffValue / hour;
      var minC = diffValue / minute;
      if (monthC >= 1) {
        result = parseInt(monthC) + "个月前";
      } else if (weekC >= 1) {
        result = parseInt(weekC) + "周前";
      } else if (dayC >= 1) {
        result = parseInt(dayC) + "天前";
      } else if (hourC >= 1) {
        result = parseInt(hourC) + "小时前";
      } else if (minC >= 1) {
        result = parseInt(minC) + "分钟前";
      } else result = "刚刚";
      return result;
    },
    //添加购物车
    async onAddCartClicked () {
      if (this.userInfo.Id == 0) {
        this.$message.error("登录后再来添加物品哦");
        return false;
      }
      if (this.guigeId == 0) {
        this.$message.error("请先选择一个商品规格");
        return;
      }
      let params = {};
      params.StudentId = this.userInfo.Id;
      params.StudentName = this.userInfo.RealName;
      params.GoodsCount = this.nums;
      params.SpecId = this.guigeId;
      params.GoodsId = this.goodsId;
      console.log(params);
      const res = await shopCartAdd(params);
      if (res.success == true) {
        // this.$message.success(res.msg);
        // 成功提示
        Toast.success({
          message: res.msg,
        });
        // 添加购物车成功后请求接口获取购物车数量
        this.getQueryShopCartList()
      } else {
        // this.$message.error(res.msg);
        Toast.fail({
          message: res.msg,
        });
      }
      //   this.postShopCartAdd(params);
    },

    changedetail (itm) {
      console.log(itm.Id);
      this.$router.replace({ name: 'PCshop/goods/goodsdetail', query: { id: itm.Id } });
      this.$router.go(0);
    },
    sub () {
      if (this.nums == 0) {
        return;
      }
      this.nums--;
    },
    add () {
      this.nums++;
    },
    isgg (itm, idx) {
      // this.act = idx;
      this.$set(itm, "ischeck", "true");
    },
    isnogg (itm) {
      this.$set(itm, "ischeck", "false");
      // console.log(itm.ischeck);
      // this.act=100
    },
    addischeck2 (idx, itm) {
      // console.log(idx, itm);
      this.act = idx;
      this.guigeId = itm.id;
      // let skuarr = this.sku.list
      // let price = skuarr[idx].price
      // this.detail[idx].DefaultPrice=price
      // this.detail.DefaultPrice = price.toString().split('.')[0];
      // let str = this.detail.DefaultPrice
      // let arr = str.split('');
      // arr.splice(-2, 0, '.');
      // str = arr.join('')
      // console.log(this.detail.GoodsSpecList[idx].Price, '')
      // console.log(this.sku, '')
      this.detail.DefaultPrice = this.detail.GoodsSpecList[idx].Price
    },
    //直接购买
    topay () {
      // this.$router.push({ name: 'PCshop/goods/check'});
      if (this.userInfo.Id == 0) {
        this.$message.error("登录后再来购买物品哦");
        return false;
      }
      // return false;
      if (this.guigeId == 0) {
        this.$message.error("请先选择一个商品规格");
        return;
      }
      let goodsData = [];
      let data = {};
      data.GoodsId = this.goodsId;
      data.SpecId = this.guigeId;
      data.Count = this.nums;
      goodsData.push(data);
      console.log(goodsData, 55)
      if (goodsData.length == 0) {
        Toast.fail({
          message: "请选择要结算的商品",
        });
        return false;
      }
      this.$router.push({
        path: "/PCshop/goods/check",
        query: { goodsData: JSON.stringify(goodsData) },
      });
    },
    //ti'na'j
    toshopcar () {
      this.$router.push({ name: "PCshop/ShopCar" });
    },
    //  轮播
    chooseFatherIndex (index) {
      this.setChooseIndex(index);
      this.$refs.swiperson.changeIndex(index); //调用子组件的
    },
    etChooseIndex (index) {
      console.log("获取到index：", index, "然后设置红色的框框样式");
      //如果为0或者超出了，则不设置border
      if (index || index === 0) {
        this.chooseIndex = index; //设置选中的index
      }
    },
    youhui () {
      this.dialogVisible = true;
    },
    changebody (num) {
      if (num == 1) {
        this.isdetail = true;
      } else {
        this.isdetail = false;
      }
    },
    // 购物车列表数据
    async getQueryShopCartList () {
      let parm = {};
      parm.StudentId = this.userInfo.Id;
      const res = await queryShopCartList(parm);
      this.shopcarlength = res.response.dataCount //购物车数量
      this.$store.commit('changeshopcar', this.shopcarlength)
      // localStorage.setItem('shopnum', this.shopcarlength);
      this.isLoad = false;
      if (res.response.data.length == 0) {
        this.isEnd = true;
      }
    },
    //领取优惠卷
    async postReceiveShopCoupon (item) {
      if (this.userInfo.Id == 0) {
        // this.callApp();
        return false;
      }
      let params = {};
      params.ShopCouponId = item.Id;
      params.UserId = this.userInfo.Id;
      params.UserName = this.userInfo.RealName;
      const res = await receiveShopCoupon(params);
      if (res.success == true) {
        this.$message.success(res.response);
        item.IsReceive = 1;
      } else {
        this.$message.error(res.response);
      }
    },
    //去优惠卷查看
    toshopmy () {
      this.$router.push({
        path: "/PCshop/my/shopmy",
        query: { index: 1 },
      });
    }
  },
};
</script>
<style lang='scss' scoped>
.box {
  background: #ffffff;
}
.pc-title {
  width: 1100px;
  height: 55px;
  margin: 0 auto;
  line-height: 55px;
  color: #666666;
  font-size: 12px;
}
.content-top {
  display: flex;
  width: 1100px;
  margin: 0 auto;
  // 左边
  .content-top-left {
    .shop-bg {
      width: 400px;
      height: 400px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .shop-small-swiper {
      height: 64px;
      width: 100%;
      border: 1px solid red;
      margin-top: 20px;
    }
  }
  .content-top-right {
    min-width: 651px;
    margin-left: 40px;
    .shop-font-bg {
      font-size: 16px;
      font-weight: bold;
      color: #666666;
    }
    .shop-small-font {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      margin-top: 14px;
    }
    .shop-detail {
      width: 100%;
      min-height: 56px;
      background: #f3f3f3;
      margin-top: 15px;
      padding: 14px 0px 14px 14px;
      box-sizing: border-box;
      .price-font {
        display: flex;
        .price-font-font {
          color: #666666;
          font-size: 12px;
          margin-top: 8px;
        }
        .ml {
          margin-left: 10px;
        }
        .ml2 {
          margin-left: 20px;
        }
        .price-font-icon {
          font-size: 12px;
          color: #f40707;
          margin-top: 10px;
        }
        .price-font-number {
          font-size: 20px;
          color: #f40707;
        }
      }
      .coupon {
        display: flex;
        margin-top: 10px;
        .price-font-font {
          color: #666666;
          font-size: 12px;
        }
        .coupon-bottom {
          margin-left: 20px;
          min-width: 58px;
          height: 16px;
          padding: 0 5px;
          border: 1px solid #e1251b;
          text-align: center;
          line-height: 16px;
          font-size: 11px;
          color: #e1251b;
        }
        .coupon-font {
          font-size: 11px;
          color: #333333;
          font-weight: 400;
          margin-left: 10px;
        }
        .coupon-last {
          color: #1288f4;
          font-size: 12px;
          margin-left: 16px;
          cursor: pointer;
        }
      }
      .integral {
        display: flex;
        margin-top: 10px;
        .price-font-font {
          color: #666666;
          font-size: 12px;
        }
        .integral-bottom {
          font-size: 12px;
          margin-left: 20px;
        }
      }
    }

    .all-img {
      position: relative;
      width: 100%;
      padding-top: 17px;
      display: flex;
      .all-img-font {
        color: #666666;
        font-size: 12px;
        margin-top: 17px;
      }
      .all-img-right {
        // width: 347px;
        justify-content: space-between;
        display: flex;
        margin-left: 36px;
        .all-img-img {
          cursor: pointer;
          // position: relative;
          display: flex;
          margin-top: 10px;
          margin-left: 20px;
          img {
            width: 50px;
            height: 50px;
          }
          .shop-img-font {
            position: absolute;
            top: 100%;
            border: 1px solid #f5f5f5;
            background: #ffffff;
            box-sizing: border-box;
          }
        }
      }
    }

    .num {
      margin-top: 27px;
      padding-top: 15px;
      display: flex;
      .all-img-font {
        color: #666666;
        font-size: 12px;
        margin-top: 17px;
      }
      .jisuan {
        width: 106px;
        height: 29px;
        display: flex;
        margin-left: 32px;
        margin-top: 10px;
        .sub {
          cursor: pointer;
          width: 25%;
          text-align: center;
          height: 100%;
          background: rgb(243, 243, 243);
          line-height: 29px;
        }
        .num-number {
          width: 50%;
          // text-align: center;
          // line-height: 29px;
          // border: 1px solid #e6e6e6;
          /deep/.el-input__inner {
            width: 100%;
            height: 100%;
            border-radius: 0;
            text-align: center;
            line-height: 29px;
            border: 1px solid #e6e6e6;
            padding: 0;
          }
        }
        .add {
          cursor: pointer;
          width: 25%;
          text-align: center;
          height: 100%;
          background: #ffffff;
          line-height: 29px;
          border: 1px solid #e6e6e6;
          border-left: none;
        }
      }
    }
    .two-bottom {
      display: flex;
      margin-top: 14px;
      padding-left: 14px;
      .one {
        width: 162px;
        height: 52px;
        background: #f95e2e;
        color: #ffffff;
        text-align: center;
        line-height: 52px;
      }
      .two {
        width: 172px;
        height: 52px;
        background: #fff1ec;
        border: 2px solid #f95e2e;
        color: #f95e2e;
        font-size: 16px;
        font-weight: bold;
        line-height: 52px;
        padding-left: 58px;
        background-image: url("../../../assets/images/card.png");
        background-repeat: no-repeat;
        background-position: 22% 49%;
        margin-left: 20px;
      }
    }
  }
}

.content {
  display: flex;
  width: 1100px;
  margin: 90px auto 39px;
  .content-hot {
    width: 180px;
    border: 1px solid #eaeaea;
    height: 770px;
  }
  .content-hot-top {
    width: 180px;
    height: 50px;
    background: #f8f8f8;
    border: 1px solid #eaeaea;
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    padding-top: 14px;
    padding-left: 18px;
    box-sizing: border-box;
  }
  .content-hot {
    padding-bottom: 19px;
    .content-hot-item {
      cursor: pointer;
      .sanjiao {
        position: absolute;
        width: 0;
        height: 0;
        border-top: 25px solid #f95e2e;
        border-right: 25px solid transparent;
        .sanjiaonum {
          position: absolute;
          top: -25px;
          left: 3px;
          font-size: 8px;
          color: #ffffff;
        }
      }
      padding-top: 14px;
      padding-left: 14px;
      position: relative;
      .hot-img {
        img {
          width: 152px;
          height: 152px;
        }
      }
      .hot-font {
        width: 145px;
        height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .hot-price {
        display: flex;
        .hot-price-icon {
          font-size: 12px;
          color: #f40707;
          margin-top: 8px;
        }
        .hot-price-nums {
          color: #f40707;
          font-size: 20px;
        }
      }
    }
  }
  .content-body {
    flex: 1;
    margin-left: 30px;
    border: 1px solid #eaeaea;
    /deep/ img {
      max-width: 100% !important;
    }
    /deep/ div {
      max-width: 100% !important;
    }
    /deep/ span {
      max-width: 100% !important;
    }
    /deep/ p {
      max-width: 100% !important;
    }

    .content-body-top {
      height: 50px;
      width: 100%;
      display: flex;
      background: #eaeaea;
    }
    .content-body-left {
      cursor: pointer;
      width: 140px;
      height: 50px;
      background: #f95e2e;
      text-align: center;
      line-height: 50px;
      color: #ffffff;
      font-weight: bold;
      font-size: 16px;
    }
    .content-body-left2 {
      cursor: pointer;
      width: 140px;
      height: 50px;
      background: #eaeaea;
      text-align: center;
      line-height: 50px;
      color: #333333;
      font-weight: 400;
      font-size: 16px;
    }
    .content-body-right {
      cursor: pointer;
      width: 140px;
      color: #333333;
      padding-left: 32px;
      line-height: 50px;
      font-size: 16px;
      font-weight: 400;
    }
    .content-body-right2 {
      cursor: pointer;
      width: 140px;
      color: #ffffff;
      background: #f95e2e;
      padding-left: 32px;
      line-height: 50px;
      font-size: 16px;
      font-weight: bold;
    }
    .content-body-center,
    .content-body-center2 {
      padding: 30px 67px 40px 50px;
    }
    .content-body-center >>> p img {
      max-width: 100% !important;
    }
    .content-body-img {
      padding-left: 50px;
      margin-bottom: 39px;
    }
  }
}

.fankui {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 660px;
  height: 439px;
  background: #ffffff;
  z-index: 999;
  margin-left: -330px;
  margin-top: -219px;
  .fankui-title {
    width: 100%;
    height: 73px;
    font-size: 18px;
    color: #333;
    font-weight: bold;
    border-bottom: 1px solid #d9d0cd;
    text-align: center;
    line-height: 73px;
  }
  .name {
    input {
      width: 460px;
      height: 36px;
      border: 1px solid #dddddd;
      padding-left: 15px;
    }
  }
  .phone {
    input {
      width: 460px;
      height: 36px;
      border: 1px solid #dddddd;
      padding-left: 15px;
    }
  }
  .content1 {
    input {
      width: 460px;
      height: 86px;
      border: 1px solid #dddddd;
      padding-left: 15px;
    }
  }
  .content1,
  .phone,
  .name {
    display: flex;
    justify-content: space-between;
    padding-right: 43px;
    margin-top: 34px;
  }
  .text {
    margin-right: 30px;
    margin-left: auto;
    margin-top: 5px;
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #c1c1c1;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c1c1c1;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c1c1c1;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #c1c1c1;
  }
}

.outisde {
  width: 100%;

  background: #fff;
  padding: 0 15px;
}

.comment_content {
  padding-bottom: 15px;
  padding-top: 10px;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;

  .user {
    display: flex;
    align-items: center;

    img {
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }

    .user_name {
      display: flex;
      margin-left: 10px;
    }

    .user_name p:first-child {
      font-size: 14px;
      color: #333333;
      font-weight: 400;
    }

    .user_name p:last-child {
      font-size: 12px;
      color: #999999;
      font-weight: 400;
      margin-left: 20px;
    }
  }

  .comment_cont {
    font-size: 14px;
    color: #333333;
    font-weight: 400;
    line-height: 20px;
    margin-top: 12px;
  }

  .image {
    margin-top: 15px;
    display: flex;

    .imgs {
      position: relative;
      width: 20%;
      padding-top: 20%;
      margin-right: 5%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
  }
}
/deep/.el-rate__text {
  display: none;
}
/deep/.el-dialog__body {
  padding: 20px 0px;
}
// 优惠卷
.Yhcontent {
  padding-top: 35px;
  text-align: center;
  border-top: 1px solid #d9d0cd;
  overflow: hidden;
  .Yhfont {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    margin-left: 15px;
    margin-top: 5px;
  }
  .two {
    color: #f95e2e;
    font-size: 16px;
    font-weight: 500;
    margin-top: 15px;
  }

  .yh {
    margin-top: 10px;
    padding-bottom: 15px;

    .yh_content {
      width: 80%;
      margin: 0 auto;
      height: 100px;
      background-image: url("../../../assets/ShopImg/yh.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      box-shadow: 5px 4px 6px rgba(146, 146, 146, 0.16);
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .yh_content_money {
        width: 32.5%;
        text-align: center;

        p {
          color: #fff;
        }
      }

      .yh_content_money p:first-child {
        font-size: 22px;
      }

      .yh_content_money p:last-child {
        font-size: 10px;
      }

      .yh_content_detail {
        margin-left: 10px;
      }

      .yh_content_detail p:first-child {
        color: #333333;
        font-size: 14px;

        font-weight: bold;
      }

      .yh_content_detail p:last-child {
        font-size: 8px;
        color: #666666;
        margin-top: 12px;
      }

      .btns {
        width: 60px;
        height: 24px;
        background: #f95e2e;
        position: absolute;
        top: 42%;
        left: 83%;
        border-radius: 20px;
        font-size: 8px;
        color: #fff;
        line-height: 24px;
        text-align: center;
      }
    }

    .btns2 {
      width: 80%;
      margin: 0 auto;
    }
  }
}
</style>